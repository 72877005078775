import React from "react";

function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="row align-items-center my-5">
          
          <div className="col-lg-5">
            <p className="font-weight-light fs-2">Please, feel free to contact me:</p>
            <p className="pt-5">
            Phone number : 516-542-2320 
            </p>
            <p>
            Email: info@pattigrabel.com / pattinyc18@gmail.com
            </p>
            <p>
            PG Art LLC.

            100 Quentin Roosevelt Blvd. 

            Suite 207

            Garden City, NY 11530
            </p>
            <a className="btn btn-outline-dark my-5" href="https://www.instagram.com/thespiritualspoon/" role="button">Visit my Instagram</a>
          </div>
          <div className="col-lg-5 offset-lg-1 offset-xl-2">
              <img className="img-fluid rounded" src= '/bloomingdales/bloom_sp.jpg' alt="pic" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
