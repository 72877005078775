import React, { useEffect } from "react";

function Qrcode() {

  useEffect(() => {
    window.location.href = "https://donate.wck.org/GroveArtsFeedsUkraine";
  }, []);

  return (
      <div>
        <h2></h2>
      </div>
  );
}

export default Qrcode;