import React, { useEffect } from "react";
import { useParams } from "react-router";
import Art from "./arts.json";


function Post() {
  let { postSlug } = useParams();

  console.log(Art[postSlug-1].description);
  let link = "/art/" + postSlug +"/1";
  let img_src = link + ".jpg";
  let index = Number(postSlug) + 1;
  useEffect(() => {
    // Fetch post using the postSlug
  }, [postSlug]);
  console.log(Art)
  return (
      <div className="home">
        <div className="container mb-5">
          <div className="row my-5 pb-5 mb-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <a href={img_src}>
                <img className="img-fluid rounded mb-4 mb-lg-0" src= {img_src} alt="" /></a>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <h1 className="">{Art[postSlug-1].name}</h1>
              <p className="mt-5">{Art[postSlug-1].size}</p>
              <p>{Art[postSlug-1].description}</p>
              {postSlug > 1 &&
                  <a href={index - 2}>
                    <button type="button" className="btn btn-outline-dark my-5 me-3">Prev</button></a>
              }
              {postSlug < Art.length &&
                  <a href={index}>
                    <button type="button" className="btn btn-outline-dark my-5">Next</button>
                  </a>
              }
            </div>
          </div>
        </div>
      </div>
  );
}

export default Post;
