import React from "react";



function Home() {
  return (




    <div className="home">
      <div className="container my-5">
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-4 mt-5 align-self-center">
          <img className="img-fluid flowerSpoon" src= '/home/spoon_w_flower.png' alt="pic" />
        </div>
        <div className="col-lg-4 col-md-4 mb-3">
          <h3 id = "poemheader" className="m-0 text-center">
            Circle of Spoons
          </h3>
          <p><br></br></p>
          <h4 className="m-0 text-center">
            We feed our children.
          </h4>
          <h4 className="m-0 text-center">
            Our children feed themselves.
          </h4>
          <h4 className="m-0 text-center">
            Our children feed their children.
          </h4>
          <h4 className="m-0 text-center">
            Together we feed our family.
          </h4>
          <h4 className="m-0 text-center">
            Our family feeds us. 
          </h4>
          <h4 className="m-0 text-center">
            Together we feed our community.
          </h4>
          <h4 className="m-0 text-center">
            Our community feeds us. 
          </h4>
          <h4 className="mt-0 text-center">
            To give is to receive. 
          </h4>
          <h4 className="mb-0 text-center">
            To receive is to give.
          </h4>
          <h4 className="m-0 text-center">
            Nature and nurture are one.
          </h4>
          <h4 className="m-0 text-center">
            Life is a circle of spoons.
          </h4>
          <p><br></br></p>
          <h4 className="m-0 text-center">
            - Patti Grabel
          </h4>

      </div>
      <div className="col-lg-4 col-md-4 mt-5 align-self-center">
        <img className="img-fluid rotateTwice flowerSpoon" src= '/home/spoon_w_flower.png' alt="pic" />
      </div>

        </div>
        <div className="container my-5">
          <div className="row pt-5">
            <div className="col-lg-4 col-md-5 col-sm-8 pb-5">
              <img className="img-fluid rounded" src= 'about/pg_sit.jpeg' alt="pic" />
            </div>
              <div className="col-lg-7 col-md-7 col-sm-12 offset-lg-1">
                <h4 className="text-left mb-5">Patti Grabel is an artist, writer, and producer based in New York City and Water Mill, NY.
                  As an artist, she works in a range of mediums including painting, drawing, photography, assemblage,
                  and found-object sculpture to explore imagery that reveals narratives that are at once personal and universal, whimsical and poignant, intimate and public.</h4>
                <p>Patti’s Lick the Spoon Series, primarily comprises photographic compositions printed on paper or glass depicting wooden spoons that have been painted,
                  hung to dry on a clothesline, and arranged to tell stories. Patti sometimes incorporates other elements to convey meaning including text and objects.
                  The narrative threads in each work embrace many themes including nourishment, sensuality, creative expression,
                  and the liberating and necessary act of taking chances in life.
                  In addition to their texture and sculptural quality, Patti uses spoons for their metaphorical richness. They are everyday tools and vessels.
                  Whether part of a set of heirloom silverware or a humble wooden spoon for stirring and serving, they are used around the globe.
                  Importantly, they represent central aspects of Patti’s life—nurturing family and friends and creating in her studio and kitchen.
                  The notion of licking the spoon aligns with the artist’s credo of embracing adventure, new opportunities, and unexpected paths in her both her artistic practice and life.  </p>
                <a className="btn btn-outline-dark" href="/about" role="button">Read more</a>
              </div>

          </div>
        </div>
      

          <div className="row ">
            <div className="col">
              <img className="img-fluid" src= '/sspon_project/candy_row.jpeg' alt="pic" />
            </div>
          </div>
    </div>
  );
}

export default Home;
