import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer className="pt-5 bg-light bg-transparent footer mt-auto">
        <div className="container mt-5 pt-5">
          <div className="row">
            <p className="text-center" id ="footerText">
            &copy; Patti Grabel 2022
            </p>
          </div>
          <div className="row text-center">
            <p>
              <a href="https://cyber-ren-prod.vercel.app/" id="crp"> Produced by Cyber Renaissance </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
