import React from "react";


function Feeding() {
  return (


    <div className="feeding">
   
      <div className="container my-5">
        <div className="row my-5">
            <div className="col-lg-5 offset-lg-1 col-sm-10 offset-sm-1">
              <img className="img-fluid" src="/feeding/fsf_logo.png" alt="pic" />

            </div>
            <div className="col-lg-6 mt-3">
              <h3>You can support the Spiritual Spoon Project by clicking here to make a tax-deductible contribution</h3>
              <a className="btn btn-outline-dark mb-3 btn-lg mt-3" href="https://www.justgiving.com/campaign/spiritualspoons" role="button">DONATE NOW</a>
            </div>

        </div>
        <div className="row">
          <div className="col-lg-7">
            <img className="img-fluid" src="/sspon_project/ssspoon.jpg" alt="pic" />
          </div>
        </div>
      </div>

    </div>
  );
}

export default Feeding;
