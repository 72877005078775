import React from "react";

function Press() {
  return (
    <div className="contact">
      <div className="container">

        
        <div className="row">
          <div className="col">
            <img className="img-fluid border" src= '/press/2.jpeg' alt="pic" />
          </div>
          <div className="col">
            <img className="img-fluid border" src= '/press/3.jpeg' alt="pic" />
          </div>
          <div className="col">
            <img className="img-fluid border" src= '/press/4.jpeg' alt="pic" />
          </div>
        </div>

      

        

      </div>

    </div>
  );
}

export default Press;
