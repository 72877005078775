import React from "react";

function Sspoon() {
  return (

    <div className="contact">
      <div className="container">
      <div className="container my-5">

        <div className="row my-5">
          <div className="col-lg-5 offset-lg-1">
            <img className="img-fluid" src="/sspon_project/ch_logo.png" alt="pic" />

          </div>
          <div className="col-lg-5">
            <h3>You can support the Spiritual Spoon Project by clicking here to make a tax-deductible contribution</h3>
            <a className="btn btn-outline-dark mb-3 btn-lg mt-3" href="https://secure.cityharvest.org/site/TR/VFD/General?team_id=3410&pg=team&fr_id=1161" role="button">DONATE NOW</a>
          </div>

        </div>

        <div className="row justify-content-md-center">
          <div className="col-lg-9">
            <p className="text-center fs-2">Spread, Stir, Serve, and Share Your Love with a “Spiritual Spoon” to help City Harvest feed the more than 2.5 million New Yorkers now struggling to make ends meet.</p>
          </div>
        </div>
      </div>
        <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-5 offset-lg-1">
              <img className="img-fluid" src= '/home/spoon_row.png' alt="pic" />
            </div>
            <div className="col-lg-6 col-sm-6 col-10 offset-md-1 offset-lg-1 offset-1 mt-3">
              <p>City Harvest is partnering with artist Patti Grabel on The Spiritual Spoon Project, with the goal to sell 10,000 spoons this year to help City Harvest feed 10,000 families in need.
                Each Spiritual Spoon, which can be purchased for one dollar, will help City Harvest feed one family for one day in New York City.
                Spoons are poignant reminders of how the steadfast support of friends, families, neighbors and communities impact our health and well-being. That kind of spiritual nourishment, support and inspiration has never been more important than now. 
                As meals are made and infused with the spirit of generosity and gift of giving, it is more important than ever to understand the power of feeding not only your body and soul but others, too.</p>
                
            </div>
        </div>

      
        
        

        <div className="row my-5">
          <div className="col-lg-8 col-sm-12 mt-3">
            <h1 className="pb-5">Together, we can help feed the millions of New Yorkers who struggle to put meals on their tables.</h1>
            <p className="pr-5 mp-5 pb-3">Purchase 36 Spiritual Spoons to help City Harvest feed 20 children for a week.</p>
            <p className="pr-5 mr-5 pb-3">Purchase 52 Spiritual Spoons to help City Harvest feed a family of three for two months.</p>
            <p className="pr-5 mr-5 pb-3">Purchase 99 Spiritual Spoons to help City Harvest feed a senior for nearly a year.</p>
            <p className="pr-5 mr-5 pb-3">Purchase 135 Spiritual Spoons to help City Harvest feed 17 New Yorkers for a full month.</p>
            <a className="btn btn-outline-dark mb-3" href="https://secure.cityharvest.org/site/TR/VFD/General?team_id=3410&pg=team&fr_id=1161" role="button">Donate Now</a>
          </div>
          <div className="col-lg-4">
            <img className="img-fluid" src="/sspon_project/heart.jpeg" alt=""/>
          </div>
        </div>

        <div className="row my-5 pb-5 mt-5">
          <div className="col-lg-5">
              <img className="img-fluid rounded" src="/sspon_project/music.jpeg" alt=""/>
          </div>
          <div className="col-lg-7">
            <p className="fs-5">We are all spoons in the universe with the ability to give and receive in one humble motion - the very essence of love.</p>
            <p className="fs-5 mt-5 pt-5">When we stir together as a community, we can collectively nourish bodies and nurture souls at this unprecedented time.</p>
            <p className="fs-5">It’s just that simple.</p>
          </div>
        </div>

        
        
        
      </div>
    </div>
  );
}

export default Sspoon;
