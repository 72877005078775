import React from "react";

function Bloomingdales() {
  return (
    <div className="about">
      <div className="container my-5">

        <div className="row">

          <div className="col-lg-5 col-md-5 col-sm-8 pb-5">
            <img className="img-fluid" src= '/bloomingdales/bloom.png' alt="pic" />
          </div>

          <div className="col-lg-6 col-md-7 col-sm-12 offset-lg-1">
            <h4 className="text-left mb-5">For the one-stop beauty destination, Grabel has created “Circle of Spoons” — an installation comprised 
            of a trio of oversized perfume bottles — a sculptural take on Grabel’s painted-spoon photographs.</h4>
            <p>The central outsized bottle features one of Grabel’s signature prints as its background, accented with a chain of metal 
              spoons that signify nourishing one’s family and community, as well as oneself. A second bottle has a top adorned in faux 
              cherries, emblematic of life’s sweetness, and is inspired by Grabel’s sensual spoon-inspired work, Hot Lips. The third 
              bottle is evocative of a goddess and is covered in shimmering spoons. Together, the trio are a vibrant expression of joie-de-vivre, 
              self-confidence, and individuality. </p>
            <p>Simultaneous to her showing at Bloomingdale’s, which runs through mid-February, two of Grabel’s painted-spoon photographs 
            will be on display at Galleria Ca’ d’Oro, through February 28, 2019, in New York’s Chelsea neighborhood. </p>
            <p className="mt-5">The group gallery show, BIANCO, is an ode to minimalism. Grabel has contributed her 2018 works “Serenity” and “Choice.”</p>


          </div>

          <div className="row">
            <div className="col-lg-7">
              <p>
              Grabel’s relationship with Bloomingdales has been ongoing.
              When they launched their new denim department at their flagship store in
              Midtown, she was selected by Bloomingdale’s to create a one-of-a kind pair of
              jeans. She was one of seven artists chosen, for the Design Denim Exhibit. For the
              jeans project, she said she drew inspiration from her prints, incorporating dozens
              of silver spoons filled with glossy, artificial berries wrapped with vines to evoke
              Mother Nature and symbolize the importance of physical, emotional, and spiritual
              sustenance. Ms. Grabel said she uses spoons as “universal tools that lovingly give
              and receive in a single motion.” The length of clothesline with clothespins serves as
              the belt and represents support. The back pockets are embroidered with a
              message: “spoon, feed, love, accept one another.” Her jeans will be part of
              Bloomindale’s permeant art collection.
              </p>
              <p className="mt-5">
                Recently, Grabel has been busy creating a necklace, Spiritual Spoons, as a
                piece of wearable art to remind us to nurture our bodies, minds, and souls
                daily. Originally, she created it for herself, but it got so many compliments that
                she is now making and selling them.
              </p>
              <a className="btn btn-outline-dark mb-5" href="/projects/jewerly" role="button">More about jewerly</a>
            </div>
            <div className="col-lg-4 offset-lg-1 offset-md-0">
              <img className="img-fluid" src= '/bloomingdales/denim.jpg' alt="pic" />
            </div>

          </div>

          <div className="row mt-5">
            <div className="col-lg-4">
              <img className="img-fluid" src= '/bloomingdales/jacket.jpg' alt="pic" />
            </div>
            <div className="col-lg-7 offset-lg-1 mt-4">
              <p>
                  A selection of works from her Lick the Spoon series and a solo exhibition from her Causing a Stir series were shown at Chase Edwards Contemporary in Bridgehampton, NY, in September 2017 and July 2018, respectively.
                  Subsequently, her prints were featured in a monographic presentation at Artiz gallery in New York City.
              </p>
              <p>
                Between the messages in her work and their overall aesthetic, Patti Grabel will cause a stir within us 
                all in one way or another. “My work has an intention — to connect with people, especially those who may 
                find my story and individual story-lines in my work resonate on a personal level. Of course, I hope that 
                people are also drawn to my work for its aesthetic qualities.”
              </p>
              <blockquote className="blockquote text-center mt-5 pt-5">
                <p>“Every day, we pick up a spoon metaphorically and literally. The question is, with what will we fill it?” asked Grabel.
                  “For me, today, it’s a rich stew of love, appreciation, and the deep fulfillment that comes from taking chances, 
                  expressing myself, and seizing the day.”</p>
              </blockquote>

            </div>

          </div>

        


        </div>
      </div>

      

      

      

      
  
    </div>
  );
}

export default Bloomingdales;


