import React from "react";
import { Outlet } from "react-router-dom";

function Gal() {
    return (
        <div className="home">
            <div className="container">
                <Outlet />
            </div>
        </div>
    );
}

export default Gal;
