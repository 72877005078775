import React from "react";

function Bread() {
  return (
    <div className="contact">
      <div className="container">

        <div className="row my-5">
          <div className="col">
            <p className="fs-2 text-center">
              "Breaking bread—sharing a meal—brings people together whatever their backgrounds. It
              binds families and communities and embodies humanity. That philosophy is a key
              element of my artistic practice.""
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <img className="img-fluid border" src= '/bread/pg_b.jpg' alt="pic" />
          </div>
        </div>

        
        <div className="row my-5">
          <div className="col-lg-5 offset-lg-1 ">
            <p className="fs-5">
              My pantry and studio are essentially the same—they both offer me the tools and materials
              that allow me to create. Cooking is its own form of artmaking. Like my artwork, my
              meals are made with love using ingredients that are carefully selected, creatively
              combined, and joyfully served. Spoons become my artistic collaborators. Stirring batters
              remind me of paint and paint morphs into delicious-looking, gooey batters.
            </p>
            <p>
              I thoroughly enjoy the preparing meals for my family and friends and entertaining comes
              naturally to me. Bringing people together for a home-cooked meal is a way to express my
              love and devotion to them. Dining together lifts spirits, ignites the senses, and delivers

              the important sense of belonging. An empty table is a blank canvas that I “paint” with
              colors and textures in the form of candles, linens, plates, china, and floral arrangements.
              Before guests arrive, I press pause for a moment to make sure the table is set for a night
              that that invites them to exhale and simply enjoy the pleasure of being together.
            </p>
          </div>
          <div className="col-lg-4 offset-lg-1">
            <img className="img-fluid border" src= '/bread/ch_bread.jpg' alt="pic" />
          </div>

          <div className="row my-5 text-center">
          <h1 className="m-0 text-center">The Proof is the Pudding</h1>
        </div>
        <div className="row my-5">
          <h2 className="text-center">Patti Ann Grabel</h2>
        
        </div>
          <div className="col-lg-10 offset-lg-1">
            <p>My beloved Nana Norma taught me a wonderful tradition that became a touchstone in my life.
              In her Brooklyn apartment, 303 Beverly Road, Shabbat dinner was always celebrated with her home-made challah. You could smell the sweet aroma of the baking bread as soon as we stepped off the elevator. 
              The smell led us merrily to her front door. When she answered our knock, I’d enter like a cartoon mouse floating toward delicious, buttery aromas.  After dinner, we’d take a large tote and go door to door to collect leftover challah from few of her dear friends who were neighbors in the building.  
              This was also my Nana’s way of checking in on her friends, catching up on their week, and, looking back, a very loving way to show off her adorable grandchildren (remember, we did not have Facebook back then). 
              I felt like Little Red Riding Hood holding a tote, carrying bread and collecting stories from door to door that would be part of the fabric of my life.</p> 
            <p> 
              Back at Nana’s, the magic in her kitchen would begin again.  She’d tear up the communal bread to soak overnight in cream, vanilla, sugar, eggs, and butter.
              Love was the extra secret ingredient, she’d say.  In the morning, she would bake her incredibly delectable challah bread pudding.  I would wake up to the smell which I associated with morning sunshine.
              We would divide it up so when the neighbors came calling for their portion, it was again another beautiful opportunity to welcome the day with the gift of giving.
              Nana would remind me that breaking bread—sharing a meal—brings neighbors together whatever their religion or culture.  It binds families and communities and embodies humanity.</p>
            <p>  
              After she was diagnosed with cancer, I begged her for her challah recipe.
              I wanted to reassure her that I would continue her tradition and make it exactly as she did when I had a family.
              I wanted to make sure that the aroma of Nana’s challah bread would be etched in my own children’s memories.
              I needed to taste her love, hug and kisses in every bite.  It was the only way I could somehow make sense of the fact she was leaving me.
              She said there wasn’t a recipe; challah was like life. When I got older, she said, I’d figure it all out on my own.  She had tremendous faith in me.
              Figuring out adulthood has had its highs and lows.  With motherhood came the sensation of being on an endless roller coaster ride.  On call 24/7, it’s exhilarating, rewarding, and, at times, scary and nauseating.  
              You must stay on because you’re racing around the track—up, down, and sideways with the occasional loop-the-loop—until the kids go to college.  But then what?  It felt like everyone had moved on but me.  
              Refusing to be the cliché of an empty-nester waiting for her kids to visit, I challenged myself to figure out the next chapter.</p>
            <p>  
              For a period of time, I was committed to learning the craft of writing and storytelling.
              Describing what I knew and inventing what I didn’t stoked the creative spirit that had been dormant for too long.
              But I discovered it’s a strange business where your creativity is subject to network, agents and advertisers needs. My story became their story.
              I’ve since turned to artmaking, something I’ve enjoyed since I was a child at summer camp.  Among its many pleasures is the fact that no one else can weigh in, alter, or take ownership of what is made by your own hands. 
              Wooden spoons feature prominently in my assemblages as both everyday tools and potent symbols.  They are extensions of my hands, giving and receiving love—a perfect means for nonverbal storytelling.  
              They communicate my twin passions of artmaking and cooking.  They are universal instruments.  Their forms are sensual and utilitarian, intimate and practical.
              The stories conveyed in each photographic print I make explore the fabric of my life and, as I’ve been told many times, resonate with viewers’ own experiences. 
              They celebrate liberation, passion, risk-taking, reinvention, spirituality, and aspects of life many of us put on hold while on the mommy roller coaster.  They also tell stories about handing down traditions and values from one generation to the next, like Nana’s bread pudding ritual.</p>
            <p>
              Nana was right, as grandmothers often are.  There is no recipe.  I continue to figure things out but now through the prism of decades of hard-won experience, personal growth, and self-awareness. Along with my love for family and friends, there is joy in doing, creating, experimenting, making, and sharing in my studio and kitchen.  Her memory is a blessing and her lessons continue to inspire.</p>
          </div>

          <div className="row my-5">
            <div className="col-lg-6 col-sm-12 mt-5">
              <h3>
                Patti’s Challah Bread Pudding
              </h3>
           
              <ul className="recipe mt-5">Ingredients:
                <li className="mt-5">1⁄2 tsp vanilla</li>
                <li>11⁄2 cups heavy cream</li>
                <li>11⁄2 cups whole milk</li>
                <li>1/2 cup granulated sugar</li>
                <li>1⁄4 teaspoon kosher salt</li>
                <li>4 tablespoons unsalted butter, melted and divided</li>
                <li>1 large egg</li>
                <li>3 large egg yolks</li>
                <li>4 tablespoons raw sugar</li>
              </ul>

              <p>
               *Seasonal additions:
              </p>
                <p>Summer: 1 cup fresh blueberries or fresh peaches.</p>
                <p>Fall: 1 cup of diced red apples.</p>
                <p>Winter: 1⁄2 cup of white raisins soaked in rum or 1⁄2 cup of white and semi-sweet chocolate chips .</p>
                <p>Family favorite: Dice 3 apples and sauté in butter, 1/8 tsp of apple pie spice, brown sugar and a
                    squeeze of fresh lemon juice. Simply delicious over the pudding-- add a little vanilla ice cream
                    and it’s heavenly!    </p>
              <p className="mt-5">
                1. Cut Challah Bread and place in a larger glass mixing bowl. Put aside.
              </p>
              <p>
                2. Pour cream, milk, granulated sugar, and salt mixture into a blender. Add egg and egg yolks and
                    blend, starting on low and increasing speed to high, until mixture is light and foamy, 20–30
                    seconds. Pour over bread and gently toss to coat. Cover tightly with plastic wrap and chill at
                    least 1 hour and up to 12; this gives bread time to evenly soak up custard.
              </p>
              <p>
                3. Preheat oven at 350°. Grease bottom and sides loaf pan with 2 tablespoons of butter.
              </p>
              <p>
                4. At this time, you can add, seasonal and family favorites. Brush remaining 2 Tbsp. butter over cold bread pieces. Sprinkle generously with raw sugar. Bake
                    bread pudding until lightly golden, puffed, pulling away from edges of pan, and butter is
                    bubbling, 40 -50 minutes. Let cool 1 hour before serving.
              </p>
              <p className="fs-5">
              Serving suggestions: serve with fresh whip cream, vanilla ice cream, caramelized apples or for a
              delicious breakfast food serve with warm maple syrup and/or strawberry jam.
             </p>
            
            </div>
            <div className="col mt-5">
             <img className="img-fluid border" src= '/bread/br.jpeg' alt="pic" />
             <img className="img-fluid border" src= '/bread/bloom_br.jpeg' alt="pic" />
            </div>
          </div>

          
        

        </div>

      

        

      </div>

    </div>
  );
}

export default Bread;
