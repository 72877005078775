import React from "react";

function Jewerly() {
  return (
    <div className="container my-5">
      <div className="row">

        <div className="col-lg-6 col-md-6 offset-lg-1 mb-5 mt-5">

          <p>
            Spoons are beautiful symbols containing and releasing infinite positive energy and love. Spoons are objects that epitomize humanity, generosity, and the essential act of nourishing one another and ourselves.
            The bowl of the spoon extends like a hand to reach for what we need in life.
          </p>
          <p className="text-center mt-5 lg-pt-5 fs-2">An empty spoon eloquently signifies the beginning of a creative process and represents the potential for limitless possibilities.</p>
          <p className="mt-5">I created this necklace, Spiritual Spoons, as a piece of wearable art to remind us to nurture our bodies, minds, and souls daily. Spoons are objects that epitomize humanity, generosity, and the essential act of nourishing one another and ourselves. The bowl of the spoon extends like a hand to reach for what we need in life. 
            An empty spoon eloquently signifies the beginning of a creative process and represents the potential for limitless possibilities.
            The necklace features five sterling silver spoons on a silk cord. The number five is referred to as the number of humankind as we have five fingers, five toes, and five senses. The necklace signifies our bountiful ability to Receive, Give, Create, Accept, and Love—five empowering and vital actions engraved on each spoon.
            Wearing this necklace with the spoons close to your heart, you will notice the peaceful chime sound it makes. Think of it as a gentle signal that you are loved and to treat yourself with kindness, too.
            I like to stack the spoons on top of each other in the cup of my hand, close my eyes, and meditate to attract positive energy and create a sense of inner calm and clarity. We are all spoons in the universe with the ability to give and receive in one humble motion -- the very essence of love.</p>
          <p>Spoonfully yours,</p>
          <img className="img-fluid" src="/jewerly/sign.jpg" alt=""/>
        </div>
        <div className="col-lg-3 col-md-5 offset-lg-1 offset-md-1 offset-sm-0 mt-5">
          <img className="img-fluid" src="/jewerly/necklace.jpg" alt=""/>
          <a className="btn btn-outline-dark mt-5" href="https://www.paypal.com/webapps/shoppingcart?mfid=1646191399086_f66321169dd69&flowlogging_id=f66321169dd69#/checkout/shoppingCart" role="button">Purchase</a>
        </div>
      </div>
      
    </div>
  );
}

export default Jewerly;
