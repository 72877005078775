import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
      <nav className="navbar-light navbar navbar-expand-md ">
        <div className="container-fluid">
          <NavLink to="/"  className="navbar-brand" >
            <h4>Patti Grabel</h4>
          </NavLink>
          <button
            className="navbar-toggler ml-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#toggleMobileMenu"
            aria-controls="toggleMobileMenu"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse "  id="toggleMobileMenu">
            <ul className="navbar-nav ms-auto" >
              <li className="nav-item"  >
                <NavLink className="nav-link" to="/projects/feeding">Feeding South Florida</NavLink>
              </li>
              <li className="nav-item"  >
                <NavLink className="nav-link" to="/projects/sspoon">City Harvest</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/gal">
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item"  >
                <NavLink className="nav-link"  to="/">
                  Home
                </NavLink>
              </li>
              
              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to = "/projects" id="navbardrop" data-bs-toggle="dropdown" aria-expanded="false">
                Projects
                </NavLink>
                <div className="dropdown-menu">
                  <NavLink to="/projects/bloomingdales" className="dropdown-item" >Bloomingdale's</NavLink>

                  <NavLink className="dropdown-item" to="/projects/bread">Challah Bread Pudding</NavLink>
                  <NavLink className="dropdown-item" to="/projects/jewerly">Jewerly</NavLink>

                  <NavLink className="dropdown-item" to="/projects/feedingrest">Feeding South Florida Restaurant</NavLink>
                  <NavLink className="dropdown-item" to="/projects/meditation">Spiritual Spoon Meditation</NavLink>
                </div>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
}

export default Navigation;
