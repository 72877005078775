import React from "react";


function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mt-5">
            <p className="fs-5">
            Chaos and control describe me perfectly.  Art controls my chaos. When I write, cook, or paint, my mind has the amazing ability to create calm. 
            When I paint the spoons, they tell stories. My thoughts become their thoughts. My mind gets lost in their stories.  With every paint stroke, 
            swirling spoon, and composition, I feel energized, empowered, and enlightened. This is when the calm is at its peak. I feel the spirituality of the spoon. 
            This is when I feel blessed to have the freedom within my framework to create the reality I see for myself and the chaos and control work in unison.
            </p>
            <p className="fs-4">To enter the art world, I had to master up the courage, strength, and perseverance to go for it! I guess that is the beauty of age. Like a wooden spoon, as it cracks, gets a bit discolored, it still has many lives and many uses. I relate to spoons.  I am the spoon. 
            </p>
           
          </div>
          <div className="col-lg-4 offset-lg-1 mt-5">
            <img className="img-fluid" src= '/about/p_smile.jpg' alt="pic" />
          </div>
        </div>

      

        <div className="row my-5">
        <div className="col-lg-5  my-3">
            <img className="img-fluid border" src= '/about/be_u.jpg' alt="pic" />
          </div>
          <div className="col-lg-7 my-3">
            <p>My style and favorite mediums reflect my passion for building, love of family, and my personal story.  My practice is based on photographic compositions printed on paper or glass depicting wooden spoons that I’ve painted, hung to dry on a clothesline, and arranged on canvas.  Each tells a story with an aspirational theme.  I sometimes incorporate other references and objects to convey meaning such as text and found objects. Spoons can be seen as anthropomorphic, which I leverage.  I often approach new work with a sense of playfulness incorporating, for example, classic candies, paper butterflies, cords, or ribbons.  
                The spoon is a potent symbol for me.  In addition to their texture and sculptural quality, spoons appeal to me for their metaphorical richness.  Most importantly, they represent giving and receiving in a single, humble motion—the very essence of love. 
                I am looking forward to continuing my investigations and see where else my spoons take me.
            </p>
            <h2 className="mt-5">Life imitates art. Imagine drawing a straight dark line across a blank piece of paper. On one side of the paper you’ve got reality. On the other side, there’s imagination. As I paint I’m really just erasing that line and my world on paper and canvas merge.  </h2>
          </div>
          

        </div>

        

          
          
        
        

        <div className="row mt-5">
          <div className="col">
          <p>Artists are famously non-conformists and, as anyone who knows me will tell you, I am a born boundary-pusher—and not just in art-making.  I dive into writing, cooking and philanthropy with passion and drive trying to change the rules for the better along the way. My anxiety drives me in a very positive way. I love pushing boundaries and knocking down doors where I want to prove there is room for one more. The table is not filled, the industry is not saturated, and I am not invisible.  
              Along the way through art and writing, I learned to redefine success. I am grateful every time someone looks at and responds to my art work, reads a story, or shares a recipe. If my art speaks to them, I am grateful. If it does not, I understand and am honored they took the time to view it.  My art on the wall excites me in a way I could have never imagined possible. </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 offset-xl-2 col-sm-4">
            <img className="img-fluid Patti" src= '/about/pg_face.jpeg' alt="pic" />
          </div>

        </div>

        <div className="row my-5 mx-5">
            <div className="col-lg-10 offset-lg-1">
              <figure className="text-center">
                <blockquote className="blockquote fs-2">
                  <p>"The world sees the art and the art sees the world"</p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  Patti Grabel
                </figcaption>
              </figure>
              <br />
              <h3 className="m-0 text-center">
                The notion of licking the spoon aligns with the artist’s credo of embracing adventure, new opportunities, and unexpected paths in her both her artistic practice and life. 
              </h3>
            </div>
        </div>

        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <img className="img-fluid text-center" src="/art/23/1.jpg" alt="pic" />
          </div>
        </div>

        

        

        
      </div>
    </div>
  );
}

export default About;
