import React from "react";

function Meditation() {
  return (
    <div className="contact">
      <div className="container">
        <div className="row my-5">
          <div className="col-lg-6 col-sm-12 col-xl-5">
            <h2>Spiritual Spoon Meditation Practice</h2>
            <h4 className="mt-5">Why meditate on a  spoon? </h4>
            <p className="mt-5">A spoon is a beautiful Zen like object that is circular, stable and  giving.  
              A spoon rests, sits and stands proudly and independently alone, it doesn’t not need 
              a fork or a knife for assistance.  A revered tool, that used around the globe, no matter 
              what race, religion or  socio-economic background you are from – a spoon is  used for the 
              same purpose to feed yourself and others. As a wooden spoon ages, it gets stained with one’s 
              familial spices: turmeric, cumin, curry, paprika and cracks and patina’s  from use, yet steeped 
              with history and tradition, it carries on with power and purpose. Similar characteristics  
              to the users own ageing process. I believe we agelike a spoon and we share its stories. 
              Where there are timeworn spoon sin kitchens everywhere we know there is love. We feed our 
              children, our children feed themselves, our children feed their children, our children feed us- 
              life is a circle of spoons.</p>
            <p>Spoons,  have  the unique ability to give and receive light in one humble motion. The very simplistic 
              and mindful act of feeding yourself with a spoon is one that should be recognized as an offering of 
              self-love.  A spoon extends, your hand, arm, heart and soul…when you lift a spoon filled with health 
              and goodness and place it into  your mouth ingesting the energy you deliberately gave to yourself 
              is the most beautiful act of kindness. You are telling yourself you  matter.  </p>
          </div>
          <div className="col-lg-6 col-sm-12 offset-xl-1 offset-lg-0 offset-sm-0">
            <img className="img-fluid" src= '/meditation/hand.jpg' alt="pic" />
          </div>
        </div>

        <div className="row my-5">
          <div className="col">
            <p className="fs-3 text-center">In the same gesture,  when we stir, serve, scoop with a spoon that is filled with nourishment  for another this is an organic and deepfelt spiritual practice – offering unconditional love and spreads light.  The energy you give is the energy you receive. </p>
          </div>
        </div>
        
        <div className="row my-5">
          <div className="col-lg-5 col-sm-12">
            <img className="img-fluid heart rounded" src= '/meditation/p_m.jpg' alt="pic" />
          </div>
          <div className="col-lg-7 col-sm-12">
            <p className="">Yes, a spoon contains energy. When we hold a spoon it becomes an extension of our being. 
            The spoon extends the hand, arm, heart and soul. This extension, commitment and passion to what we eat 
            and cook for others is felt inside our bodies. This is why we  crave a certain soups, stews, cakes, sauces 
            prepared by loved ones.  The first bite, is beyond powerful. It’s electric. It’s sends an electrical  
            current  running through our body, a charge similar to a great kiss, we feel the love the second the food 
            touches out lips, tongue and swallowed.  All our senses work in unison, memory, taste, smell, sight, and 
            we know inherently we are loved. This love fuels our body with positive energy . Offering healing,  a 
            connection to another, on  a higher and spiritual level.  </p>
            <p>All our senses become alive when are able to connect to the devotion of the dish it’s considered a spiritual  awakening.   
              We can actually taste, feel , sense, all the preparation steps which include;  selecting for the most 
              ripe, fresh, and delicious ingredients at the market, the  gentle  and skillful  peeling, chopping and 
              measuring of these prized ingredients,  as the spoon swirls love is infused and heavenly mixed into the 
              dish.  Then the ultimate act of deliverance is when the dish is completed, a sense of pride and excitement 
              fills the air  - just knowingly the recipient is craving, wanting and desiring your meal  is its own type  
              aphrodisiac.  A healthy high, and exchange of  pure light.  This is the ultimate act of giving and 
              receiving in one humble motion- the very essence of the spiritual spoon. </p>
              <p>During the day, we lift a spoon countless times, whether it  is to feed a newborn, a dollop of whip cream for a lover, 
              a healthy reward of  fruit yogurt after the gym, a celebratory ice cream on a hot summers day, a warm soup to soothe the an a
              iling soul, the list is endless and used for all loving reasons. The Spiritual Spoon  practice is designed to help people use 
              this simple tool, as meditative act of awareness.</p>
            <p className="mt-5">To keep your mind from wondering on focus on the goodness and intent of eating, cooking, and self-satisfaction.  One must love 
              themselves first in order to fully love another. This is not a selfish -act, it is a vital act, you are your greatest gift.  Many of us feel
               we need to make sure everyone else is healthy, happy, nourished : metaphysically,  physically, emotionally and spiritually –at the expense 
               of our own needs, at times.  Yes, we were put on this planet to work in unison, to be givers, but not at the expense of your own personal well-being and spiritual fulfillment. </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-lg-12 col-sm-12">
            <p className="fs-3 text-center my-5">When we pick up a spoon to feed ourselves, others and to cook, the spoon intuitively becomes an extension of your hand, arm, heart and soul…working in unison. </p>
          </div>

        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <p>A new wooden spoon,  in a sense like a blank piece of paper, canvas, seed,  embryo … open to endless possibilities to create traditions, love,  joy, health, and harmony in your home and in your soul. </p>
            <p>The very act of cooking for another is pure light. A selfless act that creates harmony within two souls. The second someone tastes the food you have prepared for them, they are not only tasting the dish, 
              they are tasting the essence of you, your love -- the key ingredient that satisfies ones appetite and why one  feels satisfied after a good meal. </p>
            <p>The love they feel, in turn is the light, it is illuminated  from their being and is transferred to your soul.  The light then reflects off you, and brighten your aura. 
              You become your own source of power. </p>
            <p>The circular motion of cooking and meditating on your spoon – will unleash your creativity and help you focus on the present, mindfulness, and meaningfulness. Offering balance within.</p>
            <p>One spoon, one hand, one heart, one soul, we are all spoons in the universe stirring what we want, need and desire out of life….remembering it is ours to create. </p>
            <p className="fs-5">How will you fill your spoon today?</p>
          </div>
          <div className="col">
            <img className="img-fluid" src= '/meditation/wooden_spoon.jpg' alt="pic" />
          </div>
        </div>

        <div className="row my-5">
          <div className="col">
            <h2 className="text-center">The Spiritual Spoon Recipe and Ritual </h2>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-lg-10 offset-lg-1">
            <p>1. 3 small bowl are placed in front of each person.  Bowl one is oil. Bowl two is filled with 
              water Bowl three is filled with a few tablespoons of dried sage and  kosher salt. (gloves can be 
              offered of people prefer not to get oil on their hands). </p>
            <p>2. Pass out wooden spoon, with your right hand, holding the handle, the student accepts by 
              grabbing the bowl of the spoon. Instructor and Person both hold the spoon and feel the connection. 
              Nod with gratitude. Continue process till all people have their spoon.
            </p>
            <p>3. The instructor takes the spoon in their right hand and holds the bowl of the spoon totheir heart. The class follows.</p>
            <p>4. Mantra: The spoon, extends your hand, arm, heart and soul…infusing love into everything give,  receive, create, accept and love. </p>
            <p>5. The class is now handed the Spoon Mantras Cards. Each class member reads is handed two cards, reads them one out loud – and makes a personal statement in reference to what they has read. 
            </p>
          </div>
        </div>

        <div className="row justify-content-around">
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Sharing a meal binds families and communities and embodies humanity.  
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Spoons give and receive in a single, humble motion—the very essence of love.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              We feed our children, our children feed themselves, our children feed their children, our children feed us—life is a circle of spoons. 
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              A spoonful of love feeds twin desires: spiritual and sensual.  
            </div>
          </div>
          
        </div>

        <div className="row  my-5 justify-content-around">
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Spoons filled with nourishment feed our souls physically, mentally, and spiritually.    
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
                Feed your own spiritual, emotional, and creative appetites.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Spoons exemplify adaptability, creativity, and artistry.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              The definition of you is yours to create.
            </div>
          </div>
        </div>

        <div className="row  my-5 justify-content-around">
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Spoons can be playful and fun, capable of releasing the inner child in all of us.   
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              An empty spoon eloquently signifies the beginning of a creative process and represents the potential to imagine limitless possibilities. 
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              A spoon is a blank canvas primed for the introduction of tastes, colors, aromas, and textures.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              A spoon can deliver spicy heat or soothing coolness. It can stir and serve, give and receive. 
            </div>
          </div>
        </div>

        <div className="row  my-5 justify-content-around">
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Every day offers myriad choices to feed yourself and others spiritually, metaphorically, and literally.    
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Infuse love with every stir, stroke, swirl of your spoon—an extension of your hand and heart.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Every time we pick up a spoon, it is a symbolic of a new beginning full of possibilities for nourishment, growth, and renewal. 
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Always have room for one more person at your table. 
            </div>
          </div>
        </div>

        <div className="row  my-5 justify-content-around">
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              An open heart is like the vessel of the spoon, reaching out and drawing in with kindness, acceptance, and self-love.   
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Believe and know inherently that you have the right to be yourself, live your values, and realize your potential no matter your age or circumstance.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Cooking is artmaking using ingredients carefully selected, creatively combined, and joyfully served.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Friendship is one of life’s most precious gifts, providing safe harbor and delivering bountiful spoonfuls of comfort, joy, empathy, laughter, love, and more. 
            </div>
          </div>
        </div>

        <div className="row  my-5 justify-content-around">
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              When we feel connected with our own feelings, we can grow and evolve and when we connect with another person, friendship can blossom.  
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Authentic connection to feeding yourself and soul requires self-awareness, openness, and empathy for others. 
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              By letting go of judgment and criticism, we can embrace familiar characteristics and unique qualities in ourselves and one another.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Spoons are marvelous embodiments of adaptability, creativity, and artistry.
            </div>
          </div>
        </div>

        <div className="row  my-5 justify-content-around">
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              We are spoons, giving and receiving from the universe. 
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Spoons are a union of simplicity and beauty.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              Be bold: create the reality you see for yourself.
            </div>
          </div>
          <div className="card col-lg-2">
            <div className="card-body text-center d-flex align-items-center">
              When we cook, paint, write, or reach out to neighbors, we nourish hearts, minds, and souls as well as our own.
            </div>
          </div>
        </div>


        <div className="row my-5">
          <div className="col-lg-10 offset-lg-1">
            <p>6. Collect cards, thank each person for participating and sharing.  </p>
            <p>7. It is now time to create your own spiritual spoon. One that will truly be an extension of yourself 
              as you will be infusing it with love and appreciation for the hard work ahead. Explain, it is not easy 
              to give to oneself. It is not easy to cook for oneself in a manner that is mindful and matters. 
              How you treat yourself is also how you treat the world. I want you to look at the spoon as a vessel, 
              an offering, a vehicle that can give and receive light from the universe. Infusing oil and sage into 
              the bowl of the spoon is a ritual of cleansing, renewal, beginnings. The spoon is in its embryotic stage. 
              It is now void of a story, it is now your story to share with your spoon. 
            </p>
            <p>8. Step One: Place bowl of spoon in the oil, rub counter clockwise- like the sun,  meditating on good thoughts for yourself, 
              family, peace, love, acceptance. There is peaceful music playing in the background. Suggest at one point closing your eyes and 
              really feeling the spoon, it stability, hardness, and strength. All qualities we crave for ourselves. </p>
            <p>9. Step Two: Place the dry sage and kosher salt in side bowl of spoon and continue to rub- the front , the back, the sides, working 
              the graduals into the wood. Feel the energy fingers, hands and arm. Infuse the power of positive thinking, doing, 
              creating, cooking, passion, light,  feeding, loving, giving into the spoon! Ask the class to 
              shout- LOVE- PASSION-COMMMITMENT – DEDICATION- FAMILY- TRADITION- SELF-WORTH- ACCEPTANCE- KINDNESS- HEALTH -HEALING-POWER- YOUR VOICE MATTERS- YOU MATTER! </p>
            <p>10. Step Three: Place the spoon in the bowl of water. Wash gently with your  hands. Concentrate on a fresh start, new beginning’s, renewal. </p>
            <p>11. The instructor will pass out a cloth, the students will wrap the spoon in the cloth and tie a red string around the cloth.  The string is symbolic of the gift of giving. You are giving a gift to yourself. </p>
            <p>12. The students will hold their spoon again next to their heart and repeat: Mantra: The spoon, extends your hand, arm, heart and soul…infusing 
              love into everything we create.  Give,  receive, create, accept and love.  Thank you for the tools to help me treat myself on others with grace and dignity. 
              Cup the spoon with two hands, hold it gain to your heart. 
            </p>
            <p>13. Instructor: Everyday is a choice- how will you fil your spoon? </p>
            <p>14. Each person answers this question out loud. </p>
            <p>15. The instructor stands and walks around the room placing her hands on each persons shoulders offering them a spoon blessing – each unique to the individual. She hand them the spoon mantra on a piece of paper as they leave.  </p>
            <p>16. Instructor reads poem below. </p>
            <div className="ml-5 text-center fs-5 my-5 fst-italic">  
              <p>A spoon is a vessel.</p>
              <p>How you fill it is a choice.</p>
              <p>Oil, sage, salt, water the well </p>
              <p>Massage gracefully</p>
              <p>Counter clockwise </p>
              <p>Like the sun. </p>
              <p>Infuse light. </p>
              <p className="pt-5">To give is to receive </p>
              <p>To receive is to give</p>
              <p>The very essence of love </p>
              <p className="pt-5">Healt</p>
              <p>Happiness</p>
              <p>Gratitude </p>
              <p>Family</p>
              <p>Tradition</p>
              <p className="pt-5">Sage and salt</p>
              <p>Rub, feel the spoon</p>
              <p>Sense the energy </p>
              <p>Notice the Wisdom</p>
              <p>Power and purpose. </p>
              <p>Healing and positivity.</p>
              <p className="pt-5">To give is to receive </p>
              <p>To receive is to give</p>
              <p>The very essence of love </p>
              <p className="pt-5">Awakening senses</p>
              <p>Instilling wellness </p>
              <p>Delivering self-love</p>
              <p>Find the answers within</p>
              <p className="pt-5">See the story</p>
              <p>Create the story</p>
              <p>Believe the story </p>
              <p>Be the story</p>
              <p className="pt-5">To give is to receive </p>
              <p>To receive is to give</p>
              <p>The very essence of love </p>
              <p className="pt-5">The spoon is now an extension </p>
              <p>of your hand</p>
              <p>heart and soul </p>
              <p>it is yours to use</p>
              <p>to stir and serve </p>
              <p>Yourself and others</p>
              <p className="pt-3">-Patti Grabel</p>
              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
              <p className="pt-5">Extend your spoon like a hand to give to those in need.</p>
              <p>Always have room for one more in your home, at your table </p>
              <p className="pt-5">An open spoon is an open heart. Share the love.</p>
              <p>The Spiritual Spoon is now your personal powerful tool.</p>
              <p className="pt-5">Always remember:</p>
              <p>Spoon, feed, love, accept </p>
              <p>one another….humanity at its best.</p>
              <div className="pt-5">
                <p>Namaste.</p>
              </div>
            

          </div>
        </div>

      </div>

    </div>
  );
}

export default Meditation;
