import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  About,
  Contact,
  Gal,
  List,
  Post,
  Sspoon,
  Press,
  Jewerly,
  Bloomingdales,
  Bread,
  Feeding,
  FeedingRest,
  Meditation,
  Qrcode

} from "./components";


ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/qr" element={<Qrcode />} />

      <Route path="/about" element={<About />} />
      <Route path="/projects/bloomingdales" element={<Bloomingdales />} />
      <Route path="/projects/bread" element={<Bread />} />
      <Route path="/projects/sspoon" element={<Sspoon />} />
      <Route path="/projects/jewerly" element={<Jewerly />} />
      <Route path="/press" element={<Press />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projects/feeding" element={<Feeding />} />
      <Route path="/projects/feedingrest" element={<FeedingRest />} />
      <Route path="/projects/meditation" element={<Meditation />} />

      <Route path="/gal" element={<Gal />}>
        <Route path="" element={<List />} />
        <Route path=":postSlug" element={<Post />} />
      </Route>
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);



