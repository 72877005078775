import React from "react";
import { Link } from "react-router-dom";
import Art from "./arts.json";


function Posts() {
  return (
      <div className="home">
        <div className="container">
          <h1 className="text-center">Gallery</h1>
          <div className="row align-items-center my-5">
            {Art.map(art => (
                <div key={art.id}>
                  <Link to = {art.id} className="gallery" >
                    <div className="row align-items-center my-5">
                      <div className="col-lg-4 col-md-7 offset-lg-2">
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src={'/art/'+art.id+'/1.jpg'}
                            alt="" />
                        
                      </div>
                      <div className="col-lg-4 col-md-5">
                        <h2 className="font-weight-light">{art.name}</h2>
                        <p className="mt-3">
                          {art.description}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
}

export default Posts;
