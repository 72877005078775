import React from "react";


function FeedingRest() {
  return (


    <div className="home">
   
      <div className="container my-5">

        <div className="row justify-content-md-center mt-5">
          <div className="col-lg-10">
            <figure className="text-center">
              <blockquote className="blockquote fs-2">
                <p>Breaking bread—sharing a meal—brings neighbors together whatever their religion or culture. It binds families and communities and embodies humanity.</p>
              </blockquote>
              <figcaption className="blockquote-footer">
                 <cite title="Source Title">Patti Grabel</cite>
              </figcaption>
            </figure>
          </div>

        </div>
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <img className="img-fluid" src= '/meditation/balance.jpg' alt="pic" />
            </div>
            <div className="col-lg-6 col-sm-10 col-md-12 offset-sm-1 offset-md-0 offset-lg-0 mt-3">
              <p>A spoon is a beautiful vessel, and cultural objects that epitomize humanity, generosity, and the essential act of nourishing one another and ourselves.
                Each spoon you pay forward - has a special purpose and connection to how you feed yourself and others- literally, physically and metaphorically.
                Spoons are simple tools used around the globe—humble vessels offering sustenance.
                When we pick up a spoon to feed ourselves, others and to cook, the spoon intuitively becomes an extension of your hand, arm, heart and soul...working in unison. </p>
              <p>One spoon, one hand, one heart, one soul, we are all spoons in the universe stirring hope, peace, love, joy and health for ourselves and those in need.</p>
            </div>
        </div>

        <div className="row my-5">
          <div className="col-lg-8 col-md-12 col-sm-10 offset-sm-1 offset-md-0 offset-lg-0 mt-3">
            <h5 className="">Step One:</h5>
            <p className="pr-5 mp-5 pb-3">Restaurants , retailers, corporations & individual sponsors – will commit to  purchasing
              x- amount of six-inch wooden spoons that are each embossed with hope, feed, love, health & peace inside the bowl of the spoon -
              the cost of each spoon is one .40 cents  ( this portion will be covered by sponsors)
              </p>
            <p className="pr-5 mr-5 pb-3">We are asking restaurants  , retailers corporations, individuals to purchase the spoons for one dollar
              - as the Champaign is highlighting the fact that one Spoon can help feee one
              family for one day thanks to the hard efforts of City Harvest & Feeding South Florida’s team
              </p>
            <p className="pr-5 mr-5 pb-3">We want to elevate & educate people - raise their awareness of how easy it is the help feeds those in need </p>
            <h5 className="">Step Two:</h5>
            <p className="pr-5 mp-5 pb-3"> Restaurants , retailers, corporations - donate on line to Spiritual Spoon Project/ City Harvest / Feeding South Florida- page.
              Where they will be listed as participating spoon leaders - and there will be a place for people to donate back in your honor …</p>
            <a className="btn btn-outline-dark mb-3" href="https://www.justgiving.com/campaign/spiritualspoons" role="button">Donate Now</a>
            <p className="pr-5 mr-5 pb-3">Sponsors  will receive recognition on the giving site as a sponsor of the Spiritual Spoon Project… we are looking for corporations to help us purchase the spoons and giving cards …</p>
            
          </div>
          <div className="col-lg-4">
            <img className="img-fluid rounded heart" src="/feeding/heart_.jpg" alt=""/>
          </div>
        </div>

        <div className="row my-5 pb-5 mt-5">
          <div className="col-lg-5">
              <img className="img-fluid rounded" src="/feeding/blue.jpg" alt=""/>
          </div>
          <div className="col-lg-7">
            <h5 className="">
              Step Three:  How the Project will Work:</h5>
            <p className="pr-5 mp-5 pb-3">Restaurants , retailers, corporations  will give away  the spoons they purchased.  Yes, give them away as gifts of giving!</p>
            <p className="pr-5 mp-5 pb-3">Cause  A Stir in your restaurant, store, community, dinner table, charity event, birthday celebration ….get creative!
              The spiritual spoons you “share” with patrons, guests,
              friends and family will have a note which reads:</p>
              <p className="text-center">"A donation has been made in honor of you to help to feed millions of families who struggle to put meals on their table in New York City & South Florida"</p>
            <p className="pr-5 mp-5 pb-3">The Spoon and card will be neatly tucked inside the customers check - at the end of the meal  the waiter will explain
              - the project - the restaurant has made a donation in their honor with gratitude - and when time permits -
              there is a QR code on the back of the card - to learn more about the project and “ feed forward”
              The hope is the guests who receive the spoon and information  card will have a meaningful conversation
              at their table- and feel grateful for being thanked for their patronage  </p>
              </div>
          <h5 className="text-center">The card will read : </h5>
          <p className="text-center lg-pr-5 lg-mr-5 lg-pb-3">Your Spiritual Spoon is a metaphor for how friends, families,
            neighbors and communities support one another’s health and well-being. When you hold a spoon, it extends your hand, your heart, and your soul.
            This kind of support and inspiration has never been more important than it is today.</p>
          <p className="mt-5">Please spread hope, love, joy, peace and health & stay connected by posting a photo of you holding your spoon on Instagram @thespiritualspoon. Use the hashtags:  #WeAreCityHarvest, #FeedingSouthFlorida and the name of restaurant gifted you your spoon.</p>

        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
           <img className="img-fluid rounded" src="/sspon_project/feed.jpg" alt=""/>
          </div>
          <div className="col-lg-6 col-sm-12">
            <p className="fs-4 sm-pt-3">The Spiritual Spoon Project goal is to have 50 restaurants in NYC and Miami  participate- on the same day - the Spiritual Spoon Saturday Serving Project  spreading and sharing love & hope…

              The amount raised is unknown but the message we spread is priceless! </p>
            <p><br></br></p>
            <p className="fs-3">Literally and metaphorically, how will you fill your spoon today? I will fill mine with gratitude, hope, and a wish for the safety and food security for all</p>

          </div>
        </div>

      </div>


    </div>
  );
}

export default FeedingRest;
